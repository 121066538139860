import React, { useRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Container, Toolbar } from '@material-ui/core';

import ResponsiveToolbar from './ResponsiveToolbar';

import { Instagram, Spotify } from '../SocialMediaButtons/';

const useStyles = makeStyles((theme) => ({
    appBarContainer: {
        color: theme.palette.common.black,
        display: 'flex',
        alignItems: 'center'
    },
    toolbar: {
        flex: 1,
        alignItems: 'flex-end'
    }
}));

const Header = () => {
    const containerRef = useRef();
    const classes = useStyles();

    return (
        <AppBar>
            <Container innerRef={containerRef} className={classes.appBarContainer}>
                <Toolbar className={classes.toolbar}>
                    <ResponsiveToolbar menuRef={containerRef.current} />
                </Toolbar>
                {/* <Instagram />
                <Spotify /> */}
            </Container>
        </AppBar>
    );
};

export default Header;