import React, { Fragment, useState } from 'react';

import NavData from '../../Assets/NavigationData.json';

import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import { useWidth } from '../../Hooks/';

import LargeNav from './LargeNav';

const useStyles = makeStyles((theme) => ({
    button: {
        color: theme.palette.common.black
    },
    paper: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.black,
        width: '40%',
        minWidth: 200
    }
}));

const NavigateToSection = (sectionId) => {
    const element = window.document.getElementById(sectionId);

    try {
        element.scrollIntoView({
            behavior: "smooth"
        });
    } catch (error) {
        element.scrollIntoView();
    }
};

const ResponsiveToolbar = (props) => {
    const { menuRef } = props;

    const [anchorEl, setAnchorEl] = useState(null);

    const width = useWidth();
    const classes = useStyles();

    if (width === "md" || width === "lg" || width === "xl") {
        return <LargeNav
            navData={NavData}
            onNavigate={NavigateToSection}
        />;
    }

    const handleClick = ({ target }) => {
        if (anchorEl) {
            setAnchorEl(null);
        } else {
            setAnchorEl(target);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const isOpen = Boolean(anchorEl);

    return (
        <Fragment>
            <IconButton className={classes.button} onClick={handleClick}>
                <MenuIcon />
            </IconButton>
            <Menu
                anchorEl={menuRef}
                open={isOpen}
                onClose={handleClose}
                PaperProps={{
                    className: classes.paper
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                keepMounted
            >
                {NavData.map(({ label, ...others }, index) => (
                    <MenuItem key={index} {...others} color="inherit">
                        {label}
                    </MenuItem>
                ))}
            </Menu>
        </Fragment>
    );
};

export default ResponsiveToolbar;