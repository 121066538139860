import React from 'react';
import ReactDOM from 'react-dom';
import App from './UiComponents/app';

fetch("/config.json")
    .then(res => res.json())
    .then((config) => {
        ReactDOM.render(
            <App config={config} />,
            document.getElementById('root')
        );
    });