import React from 'react';
import Slider from 'react-slick';

import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';

import { useWidth } from '../../../Hooks/';

const useStyles = makeStyles(() => ({
    // imageSection: {
    //     height: '100vh'
    // },
    // imageWrapper: {
    //     maxHeight: '70vh'
    // },
    // image: {
    //     width: 'unset',
    //     maxWidth: '100%',
    //     maxHeight: '100%'
    // }
}));

const SectionId = "Images";

const Images = [
    {
        src: "/sample_images/one.jpg"
    },
    {
        src: "/sample_images/two.jpg"
    },
    {
        src: "/sample_images/three.jpg"
    }
];

const ImageGallery = () => {
    const classes = useStyles();
    const width = useWidth();

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const shouldDisableGutters = width === "lg" || width === "xl";

    return (
        <Container id={SectionId} disableGutters={shouldDisableGutters}>
            <Slider {...settings} className={classes.imageSection}>
                {Images.map(({ src }, index) => (
                    <div key={index} className={classes.imageWrapper}>
                        <img src={src} className={classes.image} />
                    </div>
                ))}
            </Slider>
        </Container>
    );
};

export default ImageGallery;