import React from 'react';
import '../Styles';

import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import { Container, CssBaseline } from '@material-ui/core';

import { ConfigContext } from '../Context/';

import { useWidth } from '../Hooks/';

import { WelcomeSection/* , Introduction, Members, Footer, Images */ } from './Sections/';
import Header from './Header/';
// import Seperator from './Seperator/';
import Theme from '../Styles/Themes/dark.js';

const useStyles = makeStyles((theme) => ({
    customBody: {
        position: 'fixed',
        left: 0,
        bottom: 0,
        overflowY: 'auto',
        overflowX: 'hidden',
        top: theme.mixins.toolbar.minHeight,
        width: '100%'
    },
    [theme.breakpoints.up("sm")]: {
        customBody: {
            top: theme.mixins.toolbar['@media (min-width:600px)'].minHeight
        }
    }
}));

const App = (props) => {
    const { config } = props;

    const classes = useStyles();
    const width = useWidth();

    const shouldDisableGutters = width !== "lg" && width !== "xl";

    return (
        <ConfigContext.Provider value={config}>
            <ThemeProvider theme={Theme}>
                <Header />
                <div className={classes.customBody}>
                    <Container disableGutters={shouldDisableGutters}>
                        <CssBaseline />
                        <WelcomeSection />
                        {/* <Seperator />
                        <Introduction />
                        <Seperator />
                        <Members />
                        <Seperator />
                        <Images /> */}
                    </Container>
                    {/* <Seperator />
                    <Seperator />
                    <Footer /> */}
                </div>
            </ThemeProvider>
        </ConfigContext.Provider>
    );
};

export default App;